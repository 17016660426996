
.grid-container {
  display: grid;
  grid-template-rows: auto 200px 1fr auto;
  grid-template-columns: 1.56fr 1.4fr 1fr 1.58fr;
  grid-gap: 0;
  height: 100vh;
  max-width: 100%;
  min-width: 870px;
}

/* @media (min-width: 64em) {

}

@media (min-width: 576px) {
}

@media (min-width: 768px) {

} */

@media (min-width: 992px) {
  body {
    padding: 60px 0 0;
  }

  .grid-container {
    max-width: 64em;
    margin: 0 auto;
  }
}

/* @media (min-width: 1200px) {
}
 */

header {
  grid-row: 1;
  grid-column: 1 / span 4;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
}

.main-content {
  grid-row: 3;
  grid-column: 2 / span 2;
  -ms-grid-column: 2;
  -ms-grid-column-span: 2;
  justify-self: center;
  -ms-grid-column-align: center;
}

footer {
  grid-row: 4;
  grid-column: 1 / span 4;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
}

/* grid testing */
.left {
  grid-column: 1 / 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-row: 2 / span 2;
}

.centre-left {
  grid-column: 2 / 2;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-row: 2 / span 1;
}

.centre-right {
  grid-column: 3 / 3;
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  grid-row: 2 / span 1;
}

.right {
  grid-column: 4 / 4;
  -ms-grid-column: 4;
  -ms-grid-column-span: 1;
  grid-row: 2 / span 1;
}

img {
  max-width: 100%;
}

.logo {
  grid-column: 4 / 4;
  -ms-grid-column: 4;
  -ms-grid-column-span: 1;
  grid-row: 3 / span 1;
  max-width: 75%;
  max-height: 280px; /* for IE 11 */
  align-self: start;
  justify-self: end;
  -ms-grid-row-align: start;
  -ms-grid-column-align: end;
}
